import React from "react"
import { Layout } from "components/layout"
import { Seo } from "components/seo"
import { DefaultHeader } from "components/headers/default-header"
import { Link as Scroll } from "react-scroll"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faAngleDown } from "@fortawesome/free-solid-svg-icons"

const navs = [
  {
    title: "About Prices",
    link: "price",
  },
  {
    title: "About Contracts",
    link: "contract",
  },
  {
    title: "About Functions",
    link: "function",
  },
  {
    title: "About Support",
    link: "support",
  },
  {
    title: "About the Campaign",
    link: "campaign",
  },
  {
    title: "Others",
    link: "others",
  },
]

const Qa = ({ location }) => {
  return (
    <>
      <DefaultHeader lang="en-US" />
      <Layout lang="en-US">
        <Seo
          pagetitle="FAQ | Frequently Asked Questions"
          pagedesc="We will answer questions about fees, contracts, features, support, campaigns, and other questions regarding the organization of Twitter campaigns for PARKLoT use."
          pagepath={location.pathname}
        />
        <main className="qa-list">
          <section className="information__heading py-10 px-4 md:pt-14 md:pb-16 md:px-0">
            <div className="container m-auto">
              <h1 className="text-white font-bold">
                Frequently Asked Questions
              </h1>
            </div>
          </section>
          <section className="navigation">
            <div className="container px-4 py-4 md:py-20 md:px-0">
              <ul className="qa-list__nav md:flex md:flex-wrap w-full">
                {navs.map((nav, index) => {
                  return (
                    <li
                      key={index}
                      className="border border-lightgrey rounded bg-white hover:bg-palegrey transition-all md:mb-6 mb-3"
                    >
                      <Scroll
                        to={nav.link}
                        className="p-3 md:pb-2 block"
                        smooth={true}
                      >
                        <div className="text-center flex justify-between align-center md:block">
                          <span className="text-sm text-text font-bold md:block">
                            {nav.title}
                          </span>
                          <FontAwesomeIcon
                            className="text-lightgrey"
                            icon={faAngleDown}
                            size="lg"
                          />
                        </div>
                      </Scroll>
                    </li>
                  )
                })}
              </ul>
            </div>
          </section>
          <hr className="border-0 bg-palegrey h-px" />
          {/* 料金 */}
          <section id="price">
            <div className="container px-4 py-10 md:py-20 md:px-0">
              <h2 className="text-title text-2xl md:text-3xl font-bold md:mb-8 mb-5">
                About Prices
              </h2>
              <dl className="qa-list__list">
                <dt className="qa-list__question">
                  Can you really offer multiple campaigns at this price? Will
                  there be no additional costs?
                </dt>
                <dd className="qa-list__data">
                  If you sign up for a fixed price plan, you can campaign as
                  many times as you wish at no additional cost.
                </dd>
              </dl>
              <dl className="qa-list__list">
                <dt className="qa-list__question">
                  Is there a pay-as-you-go fee based on the number of
                  applications, winners, or engagements, or is there a data
                  acquisition limit?
                </dt>
                <dd className="qa-list__data">
                  There are no pay-as-you-go charges at this time.
                  <br />
                  However, if your account has more than 100,000 existing
                  followers and expected participation exceeds 10,000 in one
                  day, please consult with us in advance.
                </dd>
              </dl>
              <dl className="qa-list__list">
                <dt className="qa-list__question">
                  How much does it cost to download the campaign results in CSV?
                </dt>
                <dd className="qa-list__data">
                  You can download the file within the basic fee. No separate
                  fee is required.
                </dd>
              </dl>
              <dl className="qa-list__list">
                <dt className="qa-list__question">
                  Is there a difference in price between a "daily" campaign and
                  a "weekly" campaign?
                </dt>
                <dd className="qa-list__data">
                  No, it does not change.
                  <br />
                  The fee is the same whether conducted daily (31 times/month)
                  or weekly (4 times/month).
                  <br />
                  *Campaigns cannot be held at the same time.
                </dd>
              </dl>
              <dl className="qa-list__list">
                <dt className="qa-list__question">
                  What are the payment methods and payment sites?
                </dt>
                <dd className="qa-list__data">
                  The system usage fee must be paid prior to the start of use.
                  <br />
                  We will issue an invoice and payment must be made by the day
                  before the campaign starts.
                  <br />
                </dd>
              </dl>
              <dl className="qa-list__list">
                <dt className="qa-list__question">
                  Are there any discounts for annual contracts, etc.?
                </dt>
                <dd className="qa-list__data">
                  Yes, we offer discounted rates for annual contracts.
                </dd>
              </dl>
            </div>
          </section>
          <hr className="border-0 bg-palegrey h-px" />
          {/* 契約 */}
          <section id="contract">
            <div className="container px-4 py-10 md:py-20 md:px-0">
              <h2 className="text-title text-2xl md:text-3xl font-bold md:mb-8 mb-5">
                About Contracts
              </h2>
              <dl className="qa-list__list">
                <dt className="qa-list__question">
                  I want to start a campaign with PARKLoT, where can I sign up?
                </dt>
                <dd className="qa-list__data">
                  <a
                    className="qa-list__text-link"
                    href="/forms/apply"
                    target="_blank"
                    rel="noreferrer"
                  >
                    Please apply using this application form.
                  </a>
                  <br />
                  For the flow from application to the start of the campaign,
                  please refer to "Flow from Application to the Start of the
                  Campaign".
                </dd>
                <dd className="qa-list__detail">
                  <a
                    href="/en-US/qa/starting-a-campaign"
                    className="qa-list__link"
                  >
                    Flow from application to start of campaign
                  </a>
                </dd>
              </dl>
              <dl className="qa-list__list">
                <dt className="qa-list__question">
                  Is it possible to check the actual operation of the management
                  screen and the participants' guide lines on the demo screen,
                  etc. before applying?
                </dt>
                <dd className="qa-list__data">
                  Yes, it is possible. Please contact us for more information.
                </dd>
              </dl>
              <dl className="qa-list__list">
                <dt className="qa-list__question">
                  I am considering introducing the PARKLoT system, can I get a
                  pamphlet or introductory materials?
                </dt>
                <dd className="qa-list__data">
                  You can view it immediately as a PDF file from the document
                  download page.
                  <br />
                  If you would like to receive a paper copy,&nbsp;
                  <a
                    className="qa-list__text-link"
                    href="https://hub.park-lot.com/contact"
                    target="_blank"
                    rel="noreferrer"
                  >
                    please contact us using the Inquiry Form.
                  </a>
                </dd>
                <dd className="qa-list__detail">
                  <a
                    href="https://hub.park-lot.com/document-download"
                    className="qa-list__link"
                  >
                    Download PARKLoT in 3 minutes
                  </a>
                </dd>
              </dl>
              <dl className="qa-list__list">
                <dt className="qa-list__question">
                  What should the campaign organizer do after signing the
                  contract?
                </dt>
                <dd className="qa-list__data">
                  Please confirm your Twitter API application and log in to the
                  PARKLoT system.
                  <br />
                  After logging in, you can create a campaign on the
                  administration page.
                </dd>
              </dl>
              <dl className="qa-list__list">
                <dt className="qa-list__question">
                  We are considering a campaign with multiple Twitter accounts.
                  Is the contract on an account-by-account basis? Or is it per
                  company?
                </dt>
                <dd className="qa-list__data">
                  Contracts are made on a per Twitter account basis.
                </dd>
              </dl>
              <dl className="qa-list__list">
                <dt className="qa-list__question">
                  Can I run a campaign on my Twitter account for any product?
                </dt>
                <dd className="qa-list__data">
                  Yes, basically any account can host a campaign.
                  <br />
                  However, for products classified as gambling-related, adult
                  goods, virtual currency, etc.,
                  <br />
                  additional man-hours are required and will be charged
                  separately.
                </dd>
              </dl>
              <dl className="qa-list__list">
                <dt className="qa-list__question">
                  We are holding an Instant Win with another company's tool and
                  are considering switching. Can I just ask for a quote?
                </dt>
                <dd className="qa-list__data">
                  Yes, please just request a quote.
                  <br />
                  PARKLoT allows you to hold as many Instant Wins as you want
                  for a fixed price, keeping costs low.
                  <br />
                  In addition, we have the resources to develop our own
                  campaigns, so we can also make proposals
                  <br />
                  to improve the level of campaigns currently being held by
                  other companies.
                </dd>
              </dl>
              <dl className="qa-list__list">
                <dt className="qa-list__question">
                  Do I need to follow any procedures for cancellation?
                </dt>
                <dd className="qa-list__data">No special requirements.</dd>
              </dl>
            </div>
          </section>
          <hr className="border-0 bg-palegrey h-px" />
          {/* 機能 */}
          <section id="function">
            <div className="container px-4 py-10 md:py-20 md:px-0">
              <h2 className="text-title text-2xl md:text-3xl font-bold md:mb-8 mb-5">
                About Functions
              </h2>
              <dl className="qa-list__list">
                <dt className="qa-list__question">
                  What features are included in the fixed price plan?
                </dt>
                <dd className="qa-list__data">
                  Follow &amp; Retweet instant win campaign functionality is the
                  basic functionality available on a monthly basis. Free
                  optional features that can be added are listed below.
                  <br />
                  ・Collection of shipping address or e-mail address
                  <br />
                  ・Automatic message sending to campaign participants
                  <br />
                  ・Directing visitors to the Web site
                  <br />
                  ・Leading to the LINE
                  <br />
                  ・Automatic sending of fixed gift codes (automatic
                  distribution of different codes for each winner, such as
                  Amazon gift codes, is a paid option)
                  <br />
                  ・Setting the number of gifts and probability of winning for
                  each gift
                  <br />
                  ・Spam judgment based on our criteria
                  <br />
                  ・Set the image and text to be displayed at the time of
                  winning the contest.
                  <br />
                  ・Set the image and wording to be displayed when the
                  application is not selected.
                  <br />
                  ・Collect information on campaign participants and output to
                  CSV
                </dd>
              </dl>
              <dl className="qa-list__list">
                <dt className="qa-list__question">
                  What is the maximum number of retweeted user data that can be
                  collected?
                </dt>
                <dd className="qa-list__data">
                  We can collect more than 100,000 cases per day. If you need to
                  collect more than that, please contact us.
                </dd>
              </dl>
              <dl className="qa-list__list">
                <dt className="qa-list__question">
                  To what extent can I obtain information on Twitter accounts
                  that participated in the campaign?
                </dt>
                <dd className="qa-list__data">
                  Basically, we can collect profile data that is publicly
                  available on Twitter. Information that is set to private on
                  individual accounts cannot be collected.
                </dd>
              </dl>
              <dl className="qa-list__list">
                <dt className="qa-list__question">
                  Is it possible to win all the campaign participants without
                  missing out?
                </dt>
                <dd className="qa-list__data">
                  Yes, it is possible.
                  <br />
                </dd>
              </dl>
              <dl className="qa-list__list">
                <dt className="qa-list__question">
                  Is there a mechanism to reach users who participated in the
                  campaign but were not selected in the drawing?
                </dt>
                <dd className="qa-list__data">
                  Yes, we do. The campaign can be designed to direct visitors to
                  LINE, Instagram, or the company's website.
                  <br />
                </dd>
              </dl>
              <dl className="qa-list__list">
                <dt className="qa-list__question">
                  How many types of prizes can I set?
                </dt>
                <dd className="qa-list__data">Up to three types can be set.</dd>
              </dl>
              <dl className="qa-list__list">
                <dt className="qa-list__question">
                  I started a campaign with two different prize settings. Can I
                  add another one along the way?
                </dt>
                <dd className="qa-list__data">
                  Once the post has been automatically tweeted and the campaign
                  has begun, no other giveaways can be added.
                  <br />
                  For the two prizes already set, the "probability of winning"
                  and "number of winners" can be changed after the campaign
                  starts.
                </dd>
              </dl>
              <dl className="qa-list__list">
                <dt className="qa-list__question">
                  Is the notification of the results of campaigns held by
                  PARKLoT in the form of a reply or a URL?
                </dt>
                <dd className="qa-list__data">
                  URL type. The results are notified by URL in a direct message.
                  <br />
                </dd>
                <dd className="qa-list__detail">
                  <a
                    href="/en-US/qa/reply-based-or-url-based"
                    className="qa-list__link"
                  >
                    Why PARKLoT uses the URL type
                  </a>
                </dd>
              </dl>
              <dl className="qa-list__list">
                <dt className="qa-list__question">
                  Is it possible to promote using video on the results
                  notification page after the URL transition?
                </dt>
                <dd className="qa-list__data">
                  All images, GIFs, and videos can be displayed on the results
                  notification page.
                  <br />
                  The basic plan allows only jpeg/gif files to be displayed.
                  <br />
                  Video can also be displayed, but at an additional charge.
                </dd>
              </dl>
              <dl className="qa-list__list">
                <dt className="qa-list__question">
                  Is it possible to change the probability of winning during the
                  event?
                </dt>
                <dd className="qa-list__data">
                  Yes, it is possible to change the probability of winning on
                  the management screen during the campaign.
                  <br />
                </dd>
                <dd className="qa-list__detail">
                  <a
                    href="/en-US/qa/can-i-change-the-odds"
                    className="qa-list__link"
                  >
                    How to change the probability of winning
                  </a>
                </dd>
              </dl>
              <dl className="qa-list__list">
                <dt className="qa-list__question">
                  We are planning a 3-day campaign. Can I set the probability of
                  winning each day?
                </dt>
                <dd className="qa-list__data">
                  If the campaign is for three consecutive days, it is not
                  possible to set up the campaign by day.
                  <br />
                  The probability of winning can be changed during the campaign,
                  and we ask that you operate the campaign while monitoring the
                  situation.
                  <br />
                  If the campaign is held three times in a row for one day, the
                  probability of winning can be set for each day.
                </dd>
              </dl>
              <dl className="qa-list__list">
                <dt className="qa-list__question">
                  Is it possible to change the number of winners during the
                  event?
                </dt>
                <dd className="qa-list__data">
                  Yes, the number of winners can be changed on the
                  administration page during the campaign.
                  <br />
                  The number of winners doubles when we reach 10,000 retweets!"
                  and&nbsp;
                  <br />
                  other campaign designs can be designed to create a sense of
                  unity with participants.
                </dd>
              </dl>
              <dl className="qa-list__list">
                <dt className="qa-list__question">Can I filter the winners?</dt>
                <dd className="qa-list__data">
                  Yes, you can filter winners by the following criteria
                  <br />
                  ・Whether or not to allow default profile users to win
                  <br />
                  ・Minimum number of followers
                </dd>
              </dl>
              <dl className="qa-list__list">
                <dt className="qa-list__question">
                  I have a Twitter retweet campaign that was conducted without
                  using PARKLoT. Can I retrieve the users who participated in
                  this campaign later?
                </dt>
                <dd className="qa-list__data">
                  Please contact us.
                  <br />
                  Due to Twitter specifications, we can usually only retrieve
                  the most recent 100 retweeted users,&nbsp;
                  <br />
                  but depending on your situation, we may be able to retrieve a
                  higher number of retweets.
                  <br />
                </dd>
                <dd className="qa-list__detail">
                  <a
                    href="/en-US/qa/past-retweet-campaigns"
                    className="qa-list__link"
                  >
                    PARKLoT's retweet user collection mechanism
                  </a>
                </dd>
              </dl>
              <dl className="qa-list__list">
                <dt className="qa-list__question">
                  What information can I see in the report?
                </dt>
                <dd className="qa-list__data">
                  [Information that can be checked on the management screen]
                  <br />
                  ・Number of followers increased
                  <br />
                  ・Number of campaign participants
                  <br />
                  ・Winners (real time)
                  <br />
                  ・Winner's address (only if collection is turned on)
                  <br />
                  ・Winner's email address (only if collection settings are on)
                  <br />
                  [Information on participants that can be given in CSV]
                  <br />
                  ・Number of followers
                  <br />
                  ・Number of followings
                  <br />
                  ・Number of tweets
                  <br />
                  ・Profile
                  <br />
                  ・Retweet time
                  <br />
                  ・Whether or not you are following the company's official
                  account
                </dd>
              </dl>
              <dl className="qa-list__list">
                <dt className="qa-list__question">
                  Which do you recommend, using the PARKLoT system to collect
                  addresses, or putting a URL in the gift receipt wording and
                  collecting it with a Google form, etc.?
                </dt>
                <dd className="qa-list__data">
                  PARKLoT's address collection form requires you to login with
                  your Twitter account (OAuth sign-in).
                  <br />
                  This is a mechanism to prevent anyone other than the winner
                  from submitting the form.
                  <br />
                  Using PARKLoT's address collection form helps prevent fraud,
                  such as identity theft or sharing the form URL with other
                  users who have not won the campaign to enter the form.
                </dd>
              </dl>
              <dl className="qa-list__list">
                <dt className="qa-list__question">
                  Is the campaign detail page of the "PARKLoT" sweepstakes
                  portal site automatically created?
                </dt>
                <dd className="qa-list__data">
                  Yes, the detail page is created automatically.
                  <br />
                  By being listed on this site, it is expected to spread to the
                  approximately 15,000 people (as of September 30, 2022)
                  registered on the site.
                  <br />
                  In addition, it increases the likelihood that they will
                  continue to participate in the campaign.
                  <br />
                  We can also support operation with your own domain name.
                  (Additional fee required)
                  <br />
                </dd>
                <dd className="qa-list__detail">
                  <a
                    href="https://goodluck.park-lot.com/"
                    className="qa-list__link"
                  >
                    Sweepstakes portal site PARKLoT
                  </a>
                </dd>
              </dl>
            </div>
          </section>
          <hr className="border-0 bg-palegrey h-px" />
          {/* サポート */}
          <section id="support">
            <div className="container px-4 py-10 md:py-20 md:px-0">
              <h2 className="text-title text-2xl md:text-3xl font-bold md:mb-8 mb-5">
                About Support
              </h2>
              <dl className="qa-list__list">
                <dt className="qa-list__question">
                  Can I request PARKLoT to purchase Amazon gift certificates on
                  my behalf for distribution?
                </dt>
                <dd className="qa-list__data">
                  Yes, we do. 10% commission is charged when we purchase Amazon
                  gift certificates on your behalf.
                  <br />
                  The gift certificate price and handling fee must be deposited
                  before the campaign is held.
                </dd>
              </dl>
              <dl className="qa-list__list">
                <dt className="qa-list__question">
                  Is it possible to request support for planning and managing an
                  SNS campaign?
                </dt>
                <dd className="qa-list__data">
                  Yes, it is possible. Please contact us for details.
                </dd>
              </dl>
              <dl className="qa-list__list">
                <dt className="qa-list__question">
                  Can I ask you to place and manage advertisements??
                </dt>
                <dd className="qa-list__data">
                  Yes, we can help you.
                  <br />
                  Please inquire about agency fees for advertising operations.
                </dd>
              </dl>
              <dl className="qa-list__list">
                <dt className="qa-list__question">
                  Can you deliver gifts to winning users on our behalf?
                </dt>
                <dd className="qa-list__data">
                  We do not provide delivery service to winning users. Winners'
                  shipping information can be exported in CSV format.
                </dd>
              </dl>
              <dl className="qa-list__list">
                <dt className="qa-list__question">
                  Can you assist me in obtaining a certification badge?
                </dt>
                <dd className="qa-list__data">
                  Yes, we can. Please contact us for details.
                </dd>
              </dl>
            </div>
          </section>
          <hr className="border-0 bg-palegrey h-px" />
          {/* キャンペーン */}
          <section id="campaign">
            <div className="container px-4 py-10 md:py-20 md:px-0">
              <h2 className="text-title text-2xl md:text-3xl font-bold md:mb-8 mb-5">
                About the Campaign
              </h2>
              <dl className="qa-list__list">
                <dt className="qa-list__question">What is Instant Win?</dt>
                <dd className="qa-list__data">
                  Instant Win is a general term for a sweepstakes in which the
                  results of the drawing are known as soon as you participate.
                </dd>
              </dl>
              <dl className="qa-list__list">
                <dt className="qa-list__question">
                  How does Twitter Instant Win work?
                </dt>
                <dd className="qa-list__data">
                  During the campaign period, the program collects data on users
                  who retweet the tweets specified by the program.
                  <br />
                  After acquiring retweeted user data, the program will
                  automatically draw lots. Lottery results are sent via direct
                  message using Twitter's API.
                </dd>
                <dd className="qa-list__detail">
                  <a
                    href="/en-US/qa/how-twitter-instant-win-works"
                    className="qa-list__link"
                  >
                    How Twitter Instant Win Works
                  </a>
                </dd>
              </dl>
              <dl className="qa-list__list">
                <dt className="qa-list__question">
                  I am new to Instant Win campaigns, how far can you help me?
                </dt>
                <dd className="qa-list__data">
                  With an eye on the major goal of sales promotion, we will
                  provide support based on a schedule we will create,&nbsp;
                  <br />
                  covering everything from campaign content to necessary image
                  materials and operations.
                </dd>
              </dl>
              <dl className="qa-list__list">
                <dt className="qa-list__question">
                  If I use Instant Win, do I have to pay for advertising?
                </dt>
                <dd className="qa-list__data">
                  No, not required.
                  <br />
                  Advertising billing may be required for notification of
                  results in replies or when a large number of participants are
                  expected. Please inquire.
                </dd>
              </dl>
              <dl className="qa-list__list">
                <dt className="qa-list__question">
                  What kind of campaign can I organize?
                </dt>
                <dd className="qa-list__data">
                  Follow &amp; Retweet Campaign", "Hashtag Campaign" and
                  "Receipt Submission Campaign" can be held.
                  <br />
                  Drawing methods and gifts can be selected according to the
                  target audience and the products and services handled.
                </dd>
                <dd className="qa-list__detail">
                  <a
                    href="/en-US/qa/types-of-campaigns"
                    className="qa-list__link"
                  >
                    Types of campaigns that can be held at PARKLoT
                  </a>
                </dd>
              </dl>
              <dl className="qa-list__list">
                <dt className="qa-list__question">
                  Do you have any recommendations for the duration or frequency
                  of the campaign?
                </dt>
                <dd className="qa-list__data">
                  Most accounts are held two to four times a month for three to
                  seven days.
                  <br />
                  The trick to increasing the effectiveness of the event is to
                  hold it continuously for at least three months, while
                  reviewing the contents of the prizes, etc.
                </dd>
                <dd className="qa-list__detail">
                  <a href="/blog/fan-marketing" className="qa-list__link">
                    How to run a professional Twitter Instant Win Campaign
                  </a>
                </dd>
              </dl>
              <dl className="qa-list__list">
                <dt className="qa-list__question">
                  Can each person participate in the campaign once?
                </dt>
                <dd className="qa-list__data">
                  Yes, the standard setting is to participate up to once per
                  day.
                </dd>
              </dl>
              <dl className="qa-list__list">
                <dt className="qa-list__question">
                  Can I do an instant win campaign with no hassles?
                </dt>
                <dd className="qa-list__data">
                  Yes, it is possible.
                  <br />
                  PARKLoT has an "All Applicants Present" feature.
                  <br />
                  Coupon codes or QR codes can be sent to all participants who
                  were not selected in the prize drawing.
                </dd>
              </dl>
              <dl className="qa-list__list">
                <dt className="qa-list__question">
                  Can I set a condition to follow multiple accounts?
                </dt>
                <dd className="qa-list__data">
                  Yes, it can be set up as long as it is a later draw that is
                  not an instant win.
                  <br />
                  However, it is not recommended by PARKLoT&nbsp;
                  <br />
                  because it may increase the time and effort of participating
                  users and slow down the spread.
                </dd>
              </dl>
              <dl className="qa-list__list">
                <dt className="qa-list__question">
                  When is the earliest I can start the campaign?
                </dt>
                <dd className="qa-list__data">
                  If you have a Twitter account, campaign name, gift, campaign
                  period, and image for tweet submission, you can start your
                  campaign in as little as 10 minutes.
                </dd>
              </dl>
              <dl className="qa-list__list">
                <dt className="qa-list__question">
                  What kind of Twitter campaign can I run?
                </dt>
                <dd className="qa-list__data">
                  ・Follow &amp; Retweet
                  <br />
                  ・Follow &amp; Like
                  <br />
                  ・Follow &amp; Quote Retweet
                  <br />
                  ・Follow &amp; hashtag, etc.
                  <br />
                  We are flexible to other application methods. Please contact
                  us.
                </dd>
              </dl>
              <dl className="qa-list__list">
                <dt className="qa-list__question">
                  I saw the description "Of the Twitter campaign methods, we
                  only offer Like campaigns.
                </dt>
                <dd className="qa-list__data">
                  You can also run a Like campaign on PARKLoT.
                  <br />
                  We also have examples of campaigns that say, "Like us if you
                  are ~~, retweet us if you are 00".
                </dd>
              </dl>
              <dl className="qa-list__list">
                <dt className="qa-list__question">
                  Is it necessary to obtain a Twitter developer account and API
                  key?
                </dt>
                <dd className="qa-list__data">
                  If you wish to run a campaign with an immediate drawing, you
                  must obtain the Twitter API.
                </dd>
              </dl>
              <dl className="qa-list__list">
                <dt className="qa-list__question">
                  Is it difficult to apply for API with a new Twitter account?
                </dt>
                <dd className="qa-list__data">
                  New accounts are not particularly difficult.
                  <br />
                  We have also prepared materials that you can refer to when
                  communicating with Twitter, so please take advantage of them.
                </dd>
              </dl>
              <dl className="qa-list__list">
                <dt className="qa-list__question">
                  What is the process to start the campaign?
                </dt>
                <dd className="qa-list__data">
                  The basic flow is: application - data preparation and payment
                  - campaign creation - campaign start.
                </dd>
                <dd className="qa-list__detail">
                  <a
                    href="/en-US/qa/starting-a-campaign"
                    className="qa-list__link"
                  >
                    Steps to start the campaign
                  </a>
                </dd>
              </dl>
              <dl className="qa-list__list">
                <dt className="qa-list__question">
                  Is there anything the organizer should do on Twitter after the
                  campaign tweet is automatically posted?
                </dt>
                <dd className="qa-list__data">
                  The drawing is automatic, so no special work is
                  required.&nbsp;
                  <br />
                  However, to further boost the campaign, we recommend "pinning"
                  the corresponding tweet to your profile.
                  <br />
                  The official PARKLoT account will also help spread the word by
                  quoting and retweeting.
                  <br />
                </dd>
              </dl>
              <dl className="qa-list__list">
                <dt className="qa-list__question">
                  Can I distribute coupons that can be used in-store?
                </dt>
                <dd className="qa-list__data">
                  Yes, it is possible.
                  <br />
                </dd>
              </dl>
              <dl className="qa-list__list">
                <dt className="qa-list__question">
                  Can you measure the number of coupons distributed and used?
                </dt>
                <dd className="qa-list__data">
                  Yes, the number of sheets used can be measured.
                </dd>
              </dl>
              <dl className="qa-list__list">
                <dt className="qa-list__question">
                  Can I run a double chance campaign?
                </dt>
                <dd className="qa-list__data">
                  Yes, it is possible.
                  <br />
                </dd>
              </dl>
              <dl className="qa-list__list">
                <dt className="qa-list__question">
                  Can I change the wording of the DM sent after a follow &amp;
                  retweet?
                </dt>
                <dd className="qa-list__data">
                  Wording cannot be changed. Customization is available at extra
                  cost.
                </dd>
              </dl>
              <dl className="qa-list__list">
                <dt className="qa-list__question">
                  Is it possible to send a DM to participants who were not
                  selected?
                </dt>
                <dd className="qa-list__data">
                  Yes, DM can be sent even in the case of a hashish.
                </dd>
              </dl>
              <dl className="qa-list__list">
                <dt className="qa-list__question">
                  How many DMs can I send per day, totaling winners and losers?
                </dt>
                <dd className="qa-list__data">
                  Due to Twitter regulations, the limit is 1,000 messages/day.
                  (15,000 messages for verified accounts)
                </dd>
              </dl>
              <dl className="qa-list__list">
                <dt className="qa-list__question">
                  Can I distribute Amazon gift certificates as campaign prizes?
                </dt>
                <dd className="qa-list__data">
                  Yes, "Amazon Gift Certificates" can be distributed as campaign
                  prizes.
                  <br />
                </dd>
                <dd className="qa-list__detail">
                  <a
                    href="/en-US/qa/can-i-distribute-amazon-gift"
                    className="qa-list__link"
                  >
                    Distribution of Amazon Gift Certificates
                  </a>
                </dd>
              </dl>
              <dl className="qa-list__list">
                <dt className="qa-list__question">
                  An error occurred sending a DM to a winner in a campaign for
                  automatic digital gift certificate distribution. What happens
                  to the gift codes that remain unsent?
                </dt>
                <dd className="qa-list__data">
                  This information cannot be carried over to the next campaign.
                  A list of transmission errors can be shared, please respond
                  individually.
                </dd>
              </dl>

              <dl className="qa-list__list">
                <dt className="qa-list__question">
                  Can I set up ice cream and sweets that can be redeemed at
                  convenience stores and sweet stores as prizes?
                </dt>
                <dd className="qa-list__data">
                  Yes, we can.
                  <br />
                  There are services to purchase digital gifts in partnership
                  with various convenience stores and ice cream stores such as
                  7-Eleven, Lawson, Famima, etc.
                  <br />
                  Please purchase from that service.
                </dd>
              </dl>
              <dl className="qa-list__list">
                <dt className="qa-list__question">
                  Can I make completion of the survey a condition of
                  participation in the campaign?
                </dt>
                <dd className="qa-list__data">
                  Yes, it is possible.
                  <br />
                  Please contact us for more information.
                </dd>
              </dl>
              <dl className="qa-list__list">
                <dt className="qa-list__question">
                  PARKLoTでキャンペーンを作成する際に、推奨の環境はありますか？
                </dt>
                <dd className="qa-list__data">
                  Yes, we recommend using Chrome as the browser.
                  <br />
                  (even if you are using Chrome on a Windows machine, this may
                  cause a rare glitch)
                </dd>
              </dl>
              <dl className="qa-list__list">
                <dt className="qa-list__question">
                  Can I collect hashtagged posts and post them on my website?
                </dt>
                <dd className="qa-list__data">
                  Yes, posts with hashtags on Twitter and Instagram&nbsp;
                  <br />
                  can be automatically collected and displayed on the corporate
                  website.
                  <br />
                  You can easily get started by simply embedding the tags we
                  issue.
                </dd>
              </dl>
              <dl className="qa-list__list">
                <dt className="qa-list__question">
                  Can you also embed hashtagged posts on my site?
                </dt>
                <dd className="qa-list__data">
                  We cannot handle the embedding itself, as we need permission
                  to access the site.
                </dd>
              </dl>
              <dl className="qa-list__list">
                <dt className="qa-list__question">
                  We plan to hold the campaign for 7 days; what is the
                  difference between holding 7 consecutive 1-day campaigns (7
                  posts) and 1 campaign for 7 consecutive days (1 post)?
                </dt>
                <dd className="qa-list__data">
                  We recommend a type that makes 7 submissions.
                  <br />
                  If you do not advertise, impressions after the second day will
                  drop and participation will be poor.
                  <br />
                  Posting daily makes it easier to encourage and connect with
                  user participation.
                </dd>
              </dl>
              <dl className="qa-list__list">
                <dt className="qa-list__question">
                  Do I need permission from the contributor to use the
                  hashtagged post on my company's website?
                </dt>
                <dd className="qa-list__data">
                  No, not required.
                  <br />
                </dd>
              </dl>
              <dl className="qa-list__list">
                <dt className="qa-list__question">
                  When posting hashtagged posts on my site, can I choose which
                  posts to display?
                </dt>
                <dd className="qa-list__data">
                  Yes, you can show or hide posts.
                  <br />
                  The basic settings are set to hidden, so even if there are
                  inappropriate images, they will not be displayed. Please be
                  assured.
                </dd>
              </dl>
              <dl className="qa-list__list">
                <dt className="qa-list__question">
                  When posting hashtagged posts on your website, how many posts
                  are displayed?
                </dt>
                <dd className="qa-list__data">
                  By default, 50 posts are displayed. Customization is available
                  for an additional charge.
                </dd>
              </dl>
              <dl className="qa-list__list">
                <dt className="qa-list__question">
                  Can I change the design of hashtagged posts on my company's
                  website?
                </dt>
                <dd className="qa-list__data">
                  Yes, we can. We charge an optional fee of 50,000 yen and up.
                  <br />
                </dd>
              </dl>
              <dl className="qa-list__list">
                <dt className="qa-list__question">
                  When posting hashtagged posts on your own website, how often
                  do you retrieve the posts?
                </dt>
                <dd className="qa-list__data">
                  It is acquired once every 15 minutes.
                  <br />
                </dd>
              </dl>
            </div>
          </section>
          <hr className="border-0 bg-palegrey h-px" />
          {/* その他 */}
          <section id="others">
            <div className="container px-4 py-10 md:py-20 md:px-0">
              <h2 className="text-title text-2xl md:text-3xl font-bold md:mb-8 mb-5">
                Other
              </h2>
              <dl className="qa-list__list">
                <dt className="qa-list__question">
                  Can I ask you to create a banner image for my campaign?
                </dt>
                <dd className="qa-list__data">
                  Yes, we can handle this. Please contact us for more
                  information.
                </dd>
              </dl>
              <dl className="qa-list__list">
                <dt className="qa-list__question">
                  What precautions should I take when creating campaign banner
                  images in-house?
                </dt>
                <dd className="qa-list__data">
                  Some types of gift certificates require prior screening.
                  Please contact us for details.
                </dd>
              </dl>
              <dl className="qa-list__list">
                <dt className="qa-list__question">
                  Is there a specified size for banner images?
                </dt>
                <dd className="qa-list__data">
                  Banner image size of 1200 x 675 or 1080 x 1080 is recommended.
                </dd>
              </dl>
              <dl className="qa-list__list">
                <dt className="qa-list__question">
                  Is there any security when "logging in with Twitter" or
                  "linking with apps"? Will I be charged for the service?
                </dt>
                <dd className="qa-list__data">
                  No personal or other information, other than what Twitter has
                  set as public, will be divulged.
                  <br />
                  You will not be charged for this service.
                </dd>
              </dl>
              <dl className="qa-list__list">
                <dt className="qa-list__question">
                  Can I send attachments in the DM notifying me of the results?
                </dt>
                <dd className="qa-list__data">
                  If you wish to send attachments such as images and videos
                  using PARKLoT's functions, customization is required.
                </dd>
                <dd className="qa-list__detail">
                  <a
                    href="/en-US/qa/can-i-send-attachments-via-dm"
                    className="qa-list__link"
                  >
                    Attachments to DMs notifying you of the results of your
                    selection
                  </a>
                </dd>
              </dl>
              <dl className="qa-list__list">
                <dt className="qa-list__question">
                  Is it possible to customize the presentation of the screen
                  displaying the winning results?
                </dt>
                <dd className="qa-list__data">
                  Yes, we can accommodate.
                  <br />
                  The production can display images and characters that match
                  the brand's image and impress participants with the name of
                  the product or service.
                </dd>
                <dd className="qa-list__detail">
                  <a
                    href="/en-US/qa/create-scratch-campaign"
                    className="qa-list__link"
                  >
                    Customizing the Winning Result Display Screen
                  </a>
                </dd>
              </dl>
              <dl className="qa-list__list">
                <dt className="qa-list__question">
                  When conducting Instant Win, will there be any delays even if
                  a large number of applications are received?
                </dt>
                <dd className="qa-list__data">
                  Due to the system, delays may occur in rare cases.
                  <br />
                  To deal with such cases, we have adopted a flow that allows
                  you to check the results from the URL in the campaign post.
                </dd>
              </dl>
              <dl className="qa-list__list">
                <dt className="qa-list__question">
                  I want to use tweets for Follow &amp; RT campaign as promo ads
                  (promo tweets) as well. Can you link them together?
                </dt>
                <dd className="qa-list__data">
                  Yes, they can be linked.
                  <br />
                  First, please post a promo tweet with your Twitter ad account.
                  <br />
                  After that, please share the URL of the relevant tweet and we
                  will link it to the PARKLoT Instant Win system.
                  <br />
                  When a participant follows and RTs the corresponding tweet,
                  the drawing will automatically begin and a DM will be sent.
                </dd>
              </dl>
              <dl className="qa-list__list">
                <dt className="qa-list__question">
                  How long does it take to link to Promotional Tweets after
                  sharing the tweet URL?
                </dt>
                <dd className="qa-list__data">
                  It takes about 30 minutes to complete the linkage.
                </dd>
              </dl>
              <dl className="qa-list__list">
                <dt className="qa-list__question">
                  When linking to promo tweets, what happens to participants who
                  applied via follow &amp; RT before the linkage is completed?
                </dt>
                <dd className="qa-list__data">
                  Since the data is retrieved retroactively in time, there is no
                  leakage of participants between URL sharing and completion of
                  the linkage.
                </dd>
              </dl>
              <dl className="qa-list__list">
                <dt className="qa-list__question">
                  Can I run an Instant Win campaign in conjunction with PARKLoT
                  for Twitter carousel ads?
                </dt>
                <dd className="qa-list__data">
                  Yes, we can work together. Please let us know the URL after
                  submission.
                </dd>
              </dl>
              <dl className="qa-list__list">
                <dt className="qa-list__question">
                  We have been holding a follow and retweet campaign for a
                  while. The number of participants has been stagnant. Is there
                  any plan to make it fresher?
                </dt>
                <dd className="qa-list__data">
                  Scratch campaigns, slot campaigns, diagnostic content, etc.
                  can be held.
                  <br />
                  Adding a staging effect can motivate participation.
                  <br />
                  Please contact us for more information.
                </dd>
              </dl>
              <dl className="qa-list__list">
                <dt className="qa-list__question">
                  In the case of a slot campaign, I can slot many times from the
                  result notification URL, is the lottery drawn each time?
                </dt>
                <dd className="qa-list__data">
                  No. Only one drawing will be held.
                  <br />
                  No matter how many times the slot screen is displayed, the
                  lottery result remains the same.
                </dd>
              </dl>
              <dl className="qa-list__list">
                <dt className="qa-list__question">
                  Can I use it just to collect retweet users?
                </dt>
                <dd className="qa-list__data">
                  Yes, we can also list users who retweeted the corresponding
                  tweet.
                  <br />
                  Listing of likes and replies is also supported.
                </dd>
              </dl>
              <dl className="qa-list__list">
                <dt className="qa-list__question">
                  Is it possible to send a batch of DMs to users who have
                  replied to previous tweets?
                </dt>
                <dd className="qa-list__data">
                  Please contact us about sending bulk DMs to reply, like, and
                  retweet users.
                </dd>
              </dl>
              <dl className="qa-list__list">
                <dt className="qa-list__question">
                  Can I also hold a fortune or scratch-off game on line?
                </dt>
                <dd className="qa-list__data">
                  The lottery is compatible with the format of moving to the
                  link from LINE, logging in to LINE, and drawing lots. (LINE
                  Linkage)
                  <br />
                  We can create a special website, distribute gift codes,
                  collect addresses, etc. Please contact us.
                </dd>
              </dl>
              <dl className="qa-list__list">
                <dt className="qa-list__question">
                  Can I request only the collection of retweets or images?
                </dt>
                <dd className="qa-list__data">Yes, you can request it.</dd>
              </dl>
              <dl className="qa-list__list">
                <dt className="qa-list__question">
                  Can you do any promotion other than a present campaign
                  (instant win)? The purpose is to introduce and raise awareness
                  of our products.
                </dt>
                <dd className="qa-list__data">
                  Yes, we can produce cartoon ads that are easily viewed and
                  shared on Twitter.
                  <br />
                  This is a highly appealing sales promotion method that
                  introduces products in a story format.
                </dd>
              </dl>
              <dl className="qa-list__list">
                <dt className="qa-list__question">
                  I want to encourage people to sign up for a corporate LINE
                  account, is there a good plan?
                </dt>
                <dd className="qa-list__data">
                  Yes, the automatic content DM distribution feature is
                  recommended.
                  <br />
                  You can automatically send DMs to retweeted users, encouraging
                  them to link to your LINE account or company website, download
                  images, etc.
                </dd>
              </dl>
              <dl className="qa-list__list">
                <dt className="qa-list__question">
                  How many days do I have to send the giveaway?
                </dt>
                <dd className="qa-list__data">
                  There is no specific rule, but in most cases, the shipment is
                  made in about one week.
                </dd>
              </dl>
              <dl className="qa-list__list">
                <dt className="qa-list__question">
                  Can I install a campaign drawing feature on my site?
                </dt>
                <dd className="qa-list__data">
                  Yes, we can. We can incorporate a lottery system within your
                  company's managed website.
                  <br />
                  Please inquire about costs.
                </dd>
              </dl>
              <dl className="qa-list__list">
                <dt className="qa-list__question">
                  Is it possible to get a simulation of the expected results of
                  a campaign using PARKLoT?
                </dt>
                <dd className="qa-list__data">
                  Yes, we can provide you with a simulation of the expected
                  results based on the actual values of past campaigns. We can
                  provide you with a simulation of expected results based on
                  actual results of past campaigns, so please do not hesitate to
                  contact us.
                  <br />
                  (Results are not guaranteed.)
                </dd>
              </dl>
              <dl className="qa-list__list">
                <dt className="qa-list__question">
                  Is it possible to prevent users who have participated in the
                  same campaign in the past from winning?
                </dt>
                <dd className="qa-list__data">
                  Yes, it can be set up as long as it is a later draw that is
                  not an instant win.
                </dd>
              </dl>
              <dl className="qa-list__list">
                <dt className="qa-list__question">
                  Do I need a special page? Can I ask PARKLoT to create a
                  special page?
                </dt>
                <dd className="qa-list__data">
                  No, a special page is not required for a regular Instant Win
                  campaign.
                  <br />
                  If you want to enhance your brand value or plan to spread your
                  brand through diagnostic content,&nbsp;
                  <br />
                  we can help you with the creation of a special page. Please
                  contact us for more information.
                </dd>
              </dl>
              <dl className="qa-list__list">
                <dt className="qa-list__question">
                  Is there a blacklist registration function?
                </dt>
                <dd className="qa-list__data">
                  At this time, we do not have a blacklist registration
                  function.
                  <br />
                  You can set your winners narrowed down by default profile
                  account and minimum number of followers.
                </dd>
              </dl>
              <dl className="qa-list__list">
                <dt className="qa-list__question">
                  Is there a mechanism to prevent illegal lotteries?
                </dt>
                <dd className="qa-list__data">
                  Yes, you can set the default profile account not to win.
                  <br />
                  It prevents a certain number of multiple account entries for
                  prize purposes.
                </dd>
              </dl>
              <dl className="qa-list__list">
                <dt className="qa-list__question">
                  How many seconds does it take to receive an auto-reply? Is
                  there a delay?
                </dt>
                <dd className="qa-list__data">
                  Depending on the environment, the system usually takes around
                  1 minute to arrive.
                  <br />
                  Please be assured that even if there is a delay, replies will
                  be made sequentially.
                </dd>
              </dl>
              <dl className="qa-list__list">
                <dt className="qa-list__question">
                  Can I collect hashtagged posts and post them on my website?
                </dt>
                <dd className="qa-list__data">
                  Yes, posts with hashtags on Twitter and Instagram&nbsp;
                  <br />
                  can be automatically collected and displayed on the corporate
                  website.
                  <br />
                  You can easily get started by simply embedding the tags we
                  issue.
                </dd>
              </dl>
              <dl className="qa-list__list">
                <dt className="qa-list__question">
                  Can I run a campaign to increase video views?
                </dt>
                <dd className="qa-list__data">
                  Yes, it is possible to run a campaign in which users watch a
                  video and participate in a quiz,&nbsp;
                  <br />
                  or a campaign in which a special video URL is automatically
                  DM'd to only retweet users.
                </dd>
              </dl>
              <dl className="qa-list__list">
                <dt className="qa-list__question">
                  Can I also request a campaign where people can participate in
                  a stamp rally at a physical store and win a drawing?
                </dt>
                <dd className="qa-list__data">
                  Yes, we can accommodate. Please contact us for details.
                </dd>
              </dl>
            </div>
          </section>
        </main>
      </Layout>
    </>
  )
}

export default Qa
